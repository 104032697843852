<template>
  <div>
    <v-row justify="end">
      <v-col><h2>ສະຫຼຸບຍອດຕຸກນ້ຳຂອງສາຂາ</h2></v-col>
      <!--  <v-col class="al-end"> <v-btn color="primary">Export</v-btn></v-col> -->
    </v-row>
    <!-- <v-row class="my-2" justify="end">
      <v-col><h2>ທັງໝົດ {{}}</h2></v-col>
    </v-row> -->
    <!-- <hr /> -->

    <v-row class="mb-1 mt-0">
      <v-col>
        <h3 class="success--text">
          ຕຸກນ້ຳຍັງເຫຼືອ:
          {{ Intl.NumberFormat().format(sumTotalCurrentBottles) }}
        </h3>
      </v-col>
    </v-row>

    <!-- <hr /> -->
    <v-data-table
        :headers="headers"
        :items="branchBottles"
        :items-per-page="15"
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        :hide-default-footer="true"
        loading-text="Loading... Please wait"
    >
      <!--Action -->
    </v-data-table>
<br/>

<!--    Bottle empty-->
    <v-row class="mb-1 mt-0">
      <v-col>
        <h3 class="error--text">
          ຕຸກເປົ່າຍັງເຫຼືອ:
          {{ Intl.NumberFormat().format(sumTotalEmptyCurrentBottles) }}
        </h3>
      </v-col>
    </v-row>

    <!-- <hr /> -->
    <v-data-table
        :headers="headersEmptyBottle"
        :items="branchEmptyBottles"
        :items-per-page="15"
        class="elevation-1"
        :loading="TableLoading"
        :disabled="TableLoading"
        :hide-default-footer="true"
        loading-text="Loading... Please wait"
    >
      <!--Action -->
    </v-data-table>

  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `ລາຍງານຍອດຕຸກນ້ຳສາຂາ`,
    }
  },
  data() {
    return {
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      start_menu: false,
      end_menu: false,
      exportLoading: false,
      loading: false,
      TableLoading: false,
      viewModalBranch: false,
      viewModalBranchExport: false,
      branchBottles: [],
      branchEmptyBottles:[],

      sumTotalCurrentBottles:0,
      sumTotalEmptyCurrentBottles:0,

      branchId: "",
      //Pagination
      offset: 8,
      pagination: {},
      per_page: 12,
      headers: [
        { text: "ລະຫັດ", align: "center", value: "barcode" },
        {
          text: "ຜະລິດຕະພັນ",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "ລວມຕຸກນ້ຳ", align: "center", value: "countBottles" },
        { text: "ລາຄາຊື້", align: "center", value: "purchase_price" },
      ],
      headersEmptyBottle: [
        { text: "ລະຫັດ", align: "center", value: "barcode" },
        {
          text: "ຜະລິດຕະພັນ",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "ລວມຕຸກນ້ຳ", align: "center", value: "countBottles" },
        { text: "ລາຄາຊື້", align: "center", value: "purchase_price" },
      ],
    };
  },
  methods:{
    fetchBranchBottle() {
      this.TableLoading = true;
      this.$axios
          .get("/branch/list-products",)
          .then((res) => {
            setTimeout(() => {
              this.branchBottles = res.data.listProducts;
              this.sumTotalCurrentBottles = res.data.totalAmount;
              this.start_menu = false;
              this.end_menu = false;
              this.TableLoading = false;
            }, 100);
          })
          .catch(() => {
            this.TableLoading = false;
            this.end_menu = false;
            this.start_menu = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    fetchBranchEmptyBottle() {
      this.TableLoading = true;
      this.$axios
          .get("/branch/list-empty-bottles",)
          .then((res) => {
            setTimeout(() => {
              this.branchEmptyBottles = res.data.listProducts;
              this.sumTotalEmptyCurrentBottles = res.data.totalAmount;
              this.start_menu = false;
              this.end_menu = false;
              this.TableLoading = false;
            }, 100);
          })
          .catch(() => {
            this.TableLoading = false;
            this.end_menu = false;
            this.start_menu = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
  },
  created() {
    this.fetchBranchEmptyBottle();
    this.fetchBranchBottle();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}
.invoice {
  font-size: 16px;
  color: #000000;
}
.primary-color {
  color: #00c1d2;
}
</style>
